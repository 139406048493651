import React from 'react';
import { Link } from 'gatsby';
import style from './HeartDiseaseNavgrid.module.css';
import arrowSvg from '../../images/common/Arrow_Right.svg';

class HeartDiseaseNavgrid extends React.Component {
    render(){
        const styles = {
            buttonStyle: {
                "borderWidth": "0px",
                "borderColor": "#003087",
                "borderStyle": "solid",
                "textAlign": "inherit",
                "color": "inherit",
                "backgroundColor": "inherit",
                "borderBottom": "2px solid #003087",
            },
            rotate: {
                transform: 'rotate(270deg)',
            }
        }

        const navlinks = [
            ["what-are-the-signs", "What are the signs?"],
            ["understanding-heart-disease", "Understanding heart disease"],
            ["how-is-it-diagnosed", "How is it diagnosed?"],
            ["stages-of-heart-disease", "Stages of heart disease"],
            ["mitral-valve-disease", "Mitral valve disease"],
            ["dilated-cardiomyopathy", "Dilated cardiomyopathy"],
        ]

        // let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isIE = false;

        if( isIE ){
            return (
                <div className={style.HeartDiseaseNavgridContainer}>
                    <div className={style.HeartDiseaseNavgridIE}>
                        {
                            navlinks.map((el, i) => {
                                if ((this.props.heartDiseasePage-1) === i) {
                                    return (
                                        <div className={style.LinkBoxContainer} key={i}>
                                            <button className={style.HeartDiseaseNavgridLinkButtonIE}>
                                                <p>{el[1]}</p>
                                                <img src={arrowSvg} alt="Right arrow" style={styles.rotate} />
                                            </button>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={style.LinkBoxContainer} key={i}>
                                            <Link to={`/heart-disease/${el[0]}`} className={style.HeartDiseaseNavgridLinkIE} key={i}>
                                                <div className={style.HDNavgridLinkTableCell}>
                                                    <p>{el[1]}</p>
                                                    <img src={arrowSvg} alt="Right arrow" />
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className={style.HeartDiseaseNavgridContainer}>
                    <div className={style.HeartDiseaseNavgrid}>
                        {
                            navlinks.map((el, i) => {
                                // console.log('navlink props: ', this.props.heartDiseasePage);
                                if ((this.props.heartDiseasePage - 1) === i) {
                                    return (
                                        <button className={style.HeartDiseaseNavgridLink} key={i} style={styles.buttonStyle}>
                                            <p>{el[1]}</p>
                                            <img src={arrowSvg} alt="Right arrow" style={styles.rotate} />
                                        </button>
                                    )
                                } else {
                                    return (
                                        <Link to={`/heart-disease/${el[0]}`} className={style.HeartDiseaseNavgridLink} key={i}>
                                            <p>{el[1]}</p>
                                            <img src={arrowSvg} alt="Right arrow" />
                                        </Link>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            )
        }
    }
}

export default HeartDiseaseNavgrid;
