import React from 'react';
import { Link } from 'gatsby';
// import Header from '../common/Header.jsx';
import MenuLogo from '../../images/common/bi-logo-white.svg';
import CloseIcon from '../../images/common/close-icon.svg';
// import './Menu.css';
import style from './Menu.module.css';

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
        };
    }

    componentDidMount(){
        console.log('Menu props: ', this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.state.open) {
            this.setState({ open: nextProps.open });
        }
    }


    render() {
        // let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isIE = false;

        const styles = {
            // container: {
            //     // minWidth: '360px',
            //     position: 'fixed',
            //     display: 'block',
            //     top: 0,
            //     right: 0,

            //     // height: this.state.open ? '100vh' : 0,
            //     height: '100vh',
            //     // maxHeight: this.state.open ? '100vh' : 0,
            //     naxHeight: '100vh',

            //     // width: '100%',
            //     width: this.state.open ? '100vw' : 0,
            //     background: '#003087',
            //     opacity: 1,
            //     transition: 'width 0s ease',
            //     zIndex: 99,
            //     color: 'white',
            // },
            // content: {
            //     maxWidth: '1440px',
            //     // height: this.state.open ? '60%' : 0,
            //     height: '60%',
            //     margin: '0px auto',
            //     transition: 'all 0s ease',
            //     paddingLeft: '0px',
            //     paddingRight: '0px',
            //     paddingBottom: '10%',
            // },
            // list: {
            //     width: '40%',
            //     height: '80%',
            //     margin: '0px auto',
            //     display: 'flex',
            //     flexFlow: 'column nowrap',
            //     justifyContent: 'space-evenly',
            //     alignItems: 'flex-start',
            // },
            // listIE: {
            //     width: '40%',
            //     height: '100%',
            //     margin: '0px auto',
            //     display: 'block',
            // },
            // link: {
            //     display: 'inline',
            //     padding: '10px',
            //     color: 'white',
            //     textDecoration: 'none',
            //     listStyle: 'none',
            //     fontSize: '32px',
            //     cursor: 'pointer',
            //     border: '1px solid #003087',
            // },
            // linkIE: {
            //     display: 'block',
            //     padding: '10px',
            //     color: 'white',
            //     textDecoration: 'none',
            //     listStyle: 'none',
            //     fontSize: '32px',
            //     cursor: 'pointer',
            //     border: '1px solid #003087',
            // },
        }
        return (
            <div className={[style.MenuContainer, ...[(this.state.open ? style.MenuContainerOpen : null )]].join(' ')}>
                <div className={style.MenuContent}>
                    <div className={style.HeaderSpaceInMenu}>
                        <img src={MenuLogo} alt="Menu Logo" className={style.MenuLogo}/>
                        <img src={CloseIcon} alt="Close Icon" onClick={this.props.close} className={style.MenuCloseButton}/>
                    </div>
                    {
                        this.state.open ?
                        <ul className={style.MenuList}>
                            <Link onClick={this.props.close} to="/" className={style.MenuLink}>Home</Link>
                            <Link onClick={this.props.close} to="/healthy-hearts" className={style.MenuLink}>Healthy hearts</Link>
                            <Link onClick={this.props.close} to="/heart-disease" className={style.MenuLink}>Heart disease</Link>
                            <Link onClick={this.props.close} to="/early-diagnosis" className={style.MenuLink}>Benefits of early diagnosis</Link>
                            <Link onClick={this.props.close} to="/care-and-treatment" className={style.MenuLink}>Care and treatment</Link>
                            <Link onClick={this.props.close} to="/measure-health" className={style.MenuLink}>Measure your dog's heart health</Link>
                        </ul> : null
                    }
                </div>
            </div>
        )
    }
}

export default Menu;
