import React from 'react';
import Header from './Header.jsx';
import Footer from './Footer.jsx';
import style from './Layout.module.css';

class Layout extends React.Component {
    render(){
        return (
            <div className={style.LayoutContainer}>
                <Header/>
                    <div className={style.ContentContainer}>
                        <div className={style.BottomPaddingContainer}>
                            {this.props.children}
                        </div>
                    </div>                        
                <Footer/>
            </div>
        )
    }
}

export default Layout;
