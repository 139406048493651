import React from 'react';
import { Helmet } from 'react-helmet';

class HeartDiseaseHelmet extends React.PureComponent {
    render(){
        return (
            <Helmet>
                <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content="Read now for heart information in dogs." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Healthy Hearts for Dogs - Boehringer Ingelheim" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Read now for heart information in dogs." />
                <meta property="og:locale" content="en_AU" />
                <title>{this.props.titleToUse} - Boehringer Ingelheim 2019</title>
            </Helmet>
        )
    }
}

export default HeartDiseaseHelmet;
