import React from 'react';
import Layout from '../../components/common/Layout.jsx';
import HeartDiseaseLayout from '../../components/heart-disease/HeartDiseaseLayout.jsx';

import { connect } from 'react-redux';
import { HEART_DISEASE_NAVIGATION } from '../../state/actionTypes.js';

class MitralValveDisease extends React.PureComponent {
    constructor(props){
        super(props);
        props.heartDiseaseNavigation(5);
    }

    render(){
        return (
            <Layout>
                <HeartDiseaseLayout>
                    <div className="MitralValveDiseaseMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">Mitral valve disease (MVD) is the most common form of heart disease in dogs. It generally occurs in small to medium size dogs and some breeds are more susceptible than others. These breeds include Cavalier King Charles Spaniels, Poodles, Schnauzers, Chihuahuas, Fox Terriers and Boston Terriers.</p>
                                <p className="SectionText">MVD is a disease that affects the surface of the heart valves. Other names you may hear used to describe MVD are endocardiosis, myxomatous mitral valve disease (MMVD) or valvular insufficiency.</p>
                                <p className="SectionText">The valves are normally smooth and form a perfect seal when closed. MVD causes the edges to become thickened, lumpy and distorted. The seal becomes imperfect and when the ventricle pumps, some of the blood flows backwards into the atrium. This backward flow creates a noise that your vet can hear with a stethoscope. The noise is called a murmur.</p>
                                <p className="SectionText">Because the heart valves are leaky, circulation is impaired.</p>
                                <p className="SectionText">For a time the dog's body may make adjustments to allow it to cope. In fact some dogs live with a murmur for many years without developing any clinical signs or symptoms. However, eventually the disease can override the adjustments that have been made causing the dog to become unwell and start to show signs of heart failure.</p>
                                <p className="SectionText">MVD may affect the dog's body in a number of different ways. See your vet for more information.</p>
                            </div>
                        </div>
                    </div>
                </HeartDiseaseLayout>
            </Layout>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        heartDiseaseNavigation: heartDiseasePage => dispatch({
            type: HEART_DISEASE_NAVIGATION,
            heartDiseasePage,
        })
    }
}

export default connect(null, mapDispatchToProps)(MitralValveDisease);
