import React from 'react';
import { Link } from 'gatsby';
import HeaderLogo from '../../images/common/bi-logo-blue.svg';
import style from './Header.module.css';
// import Menu from './Menu';
import Menu from './Menu.jsx';

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            menuState: false,
        };
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    openMenu(){
        this.setState({
            menuState: true,
        });
        // document.body.style.overflow = 'hidden';
    }

    closeMenu(){
        this.setState({
            menuState: false,
        });
        // document.body.style.overflow = 'visible';
    }

    render(){
        // let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isIE = false;

        if( isIE ){
            return (
                <div className={style.HeaderShell}>
                    <Menu open={this.state.menuState} close={this.closeMenu} />
                    <div className={style.HeaderContentIE}>
                        <div className={style.HeaderLogoContainerIE}>
                            <Link to="/"><img src={HeaderLogo} alt="Header Logo" className={style.HeaderLogo} /></Link>
                        </div>
                        <div className={style.HeaderMenuButtonIE + ' ' + (this.state.menuState ? style.menuOn : '')} onClick={this.state.menuState ? this.closeMenu : this.openMenu}>
                            <span className={style.HeaderButtonLineIE}></span>
                            <span className={style.HeaderButtonLineIE}></span>
                            <span className={style.HeaderButtonLineIE}></span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={style.HeaderShell}>
                    <Menu open={this.state.menuState} close={this.closeMenu}/>
                    <div className={style.HeaderContent}>
                        <div className={style.HeaderLogoContainer}>
                            <Link to="/"><img src={HeaderLogo} alt="Header Logo" className={style.HeaderLogo} /></Link>
                        </div>
                        <div className={style.HeaderMenuButton + ' ' + (this.state.menuState ? style.menuOn : '')} onClick={this.state.menuState ? this.closeMenu : this.openMenu}>
                            <span className={style.HeaderButtonLine}></span>
                            <span className={style.HeaderButtonLine}></span>
                            <span className={style.HeaderButtonLine}></span>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Header;
