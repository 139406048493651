import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import HeartDiseaseBanner from './HeartDiseaseBanner.jsx';
import HeartDiseaseNavgrid from './HeartDiseaseNavgrid.jsx';
import HeartDiseaseHelmet from './HeartDiseaseHelmet.jsx';

import { connect } from 'react-redux';

class HeartDiseaseLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            compData: [
                ["Learn more about heart disease", null],
                ["What are the signs?", "Signs"],
                ["Understanding heart disease", "Understanding heart disease"],
                ["How is heart disease diagnosed?", "Heart disease diagnosis"],
                ["The stages of heart disease", "Stages of heart disease"],
                ["Mitral valve disease", "Mitral valve disease"],
                ["Dilated cardiomyopathy", "Dilated cardiomyopathy"],
            ],
            current: this.props.heartDiseasePage,
        };
    }

    static getDerivedStateFromProps(props){
        return {
            current: props.heartDiseasePage,
        }
    }

    render(){
        return (
            <StaticQuery
                query={graphql`
                    query {
                        heartDiseaseBannerImage: file(relativePath: { eq: "heartDisease/heart-disease-banner.png" }){
                            childImageSharp {
                                fluid(maxWidth: 1440, maxHeight: 659){
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                `}
                render={(data)=>(
                    <div>
                        <HeartDiseaseBanner compInfo={this.state} bannerImg={data.heartDiseaseBannerImage}/>
                        <HeartDiseaseHelmet titleToUse={this.state.compData[this.state.current][0]} />
                            {this.props.children}
                        <HeartDiseaseNavgrid {...this.props}/>
                    </div>
                )}
            />
        )
    }
};

const mapStateToProps = state => {
    return {
        heartDiseasePage: state.heartDiseaseNavigationReducer,
    }
};

export default connect(
    mapStateToProps,
    null,
)(HeartDiseaseLayout);
