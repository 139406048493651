import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import style from './HeartDiseaseBanner.module.css';

class HeartDiseaseBanner extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
        this.generateBreadcrumbs = this.generateBreadcrumbs.bind(this);
    }

    generateBreadcrumbs() {
        return !!this.props.compInfo.compData[this.props.compInfo.current][1] ? [<Link key={Math.random()} className={style.HeartDiseaseBreadcrumb} to="/heart-disease">Heart disease</Link>, ` > ${this.props.compInfo.compData[this.props.compInfo.current][1]}`] : "Heart disease";
    }

    render(){
        // let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isIE = false;

        if( isIE ){
            return (
                <div className={style.HeartDiseaseBanner}>
                    <div className={style.HeartDiseaseImageContainer}>
                        <Img
                            fluid={this.props.bannerImg.childImageSharp.fluid}
                        />
                    </div>
                    <div className={style.HeartDiseaseTitleContainerIE}>
                        <div className={style.HDTitleTable}>
                            <div className={style.HDTitleTableCell}>
                                <p className={style.HeartDiseaseTitle}>{this.props.compInfo.compData[this.props.compInfo.current][0]}</p>
                            </div>
                        </div>
                    </div>
                    <div className={style.HeartDiseaseBreadcrumbsContainer}>
                        <Link to="/" className={style.HeartDiseaseBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                        {
                            this.generateBreadcrumbs()
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className={style.HeartDiseaseBanner}>
                    <div className={style.HeartDiseaseImageContainer}>
                        <Img
                            fluid={this.props.bannerImg.childImageSharp.fluid}
                        />
                    </div>
                    <div className={style.HeartDiseaseTitleContainer}>
                        <p className={style.HeartDiseaseTitle}>{this.props.compInfo.compData[this.props.compInfo.current][0]}</p>
                    </div>
                    <div className={style.HeartDiseaseBreadcrumbsContainer}>
                        <Link to="/" className={style.HeartDiseaseBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                        { this.generateBreadcrumbs() }
                    </div>
                </div>
            )
        }
    }
}

export default HeartDiseaseBanner;
